@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.from {
	display: flex;
	align-items: center;
	flex-direction: row;
	width: 100%;
	height: 100%;

	&__price {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex: 1 1 0%;
		padding-right: 10px;
		padding-left: 10px;
	}

	&__info {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		flex: 1 1 0%;
		font-size: 1.2rem;
		border-left: 1px solid white;
		height: 70%;
		padding-right: 8px;
		padding-left: 8px;
	}

	&__duration {
		margin-right: 5px;
	}

	&__public-price {
		.amount {
			font-size: 1.2rem;

			&__value {
				text-decoration: line-through;
			}
		}
	}

	&__flight {
		&--included {
			display: inline-flex;
			align-items: center;

			.icon--flight {
				height: 9px;
				margin-left: 3px;
			}
		}
	}
}
