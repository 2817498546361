@import "~themes/typography";

:root {
	--color-primary-100: #d9ece2;
	--color-primary-200: #b2d9c5;
	--color-primary-300: #37d288;
	--color-primary-400: #00bb5b;
	--color-primary-500: #00803e;
	--color-primary-600: #004524;
	--color-primary-700: #003216;
	--color-primary-800: #001520;

	--color-secondary-100: #c8e9ff;
	--color-secondary-200: #8cd0ff;
	--color-secondary-300: #38aeff;
	--color-secondary-400: #2e90ff;
	--color-secondary-500: #2677d3;
	--color-secondary-600: #005585;
	--color-secondary-700: #024074;
	--color-secondary-800: #002856;

	--color-black: #000000;
	--color-gray-300: #f7f7f7;
	--color-gray-400: #e9e9e9;
	--color-gray-500: #bababa;
	--color-gray-600: #757575;
	--color-gray-700: #393939;

	// timer
	--color-background-timer: var(--color-secondary-500);
	--color-text-timer: white;

	// footer
	--color-background-footer: var(--color-primary-700);
	--color-text-footer: white;

	// badge inclus
	--color-background-badge-inclus: var(--color-primary-600);
	--color-text-badge-inclus: white;

	// push notification
	--color-background-push-notification: var(--color-primary-600);

	// active color
	--color-active: var(--color-primary-500);

	// payment banner
	--payment-description-background-color: var(--color-primary-100);
	--payment-description-instalments-background-color: var(--color-primary-500);

	// input-label
	--input-label-active-color: var(--color-gray-600);

	// picture shadow
	--picture-shadow-layer: rgba(var(--color-primary-800), 0.3);
}

/***************************
 * = COULEURS
 ***************************/

$color-primary: #{"var(--color-primary-500)"};
$color-primary-dark: #{"var(--color-primary-600)"};
$color-primary-light: #{"var(--color-primary-300)"};
$color-secondary: #{"var(--color-secondary-500)"};
$color-secondary-dark: #{"var(--color-secondary-600)"};
$color-secondary-light: #{"var(--color-primary-300)"};
$black: #{"var(--color-black)"};
$grey-dark: #{"var(--color-gray-700)"};
$grey-light: #{"var(--color-gray-300)"};
$grey-medium: #{"var(--color-gray-500)"};
$color-error: #a94442;
$color-valid: #0abf74;
$color-info: #3493dc;

/***************************
 * = FONTS
 ***************************/

%text {
	@extend %font-normal;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $black;
}

%text-bold {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $black;
}

%text-secondary {
	@extend %font-normal;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $grey-dark;
}

%text-secondary-bold {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $grey-dark;
}

%link {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $color-primary;
	cursor: pointer;
	text-decoration: none;

	@include gridle_state(md) {
		&:hover {
			text-decoration: underline;
		}
	}
}

/***************************
 * = COMMUN
 ***************************/

$border-radius-small: 0px;
$border-radius-big: 3px;
$border-radius-top: $border-radius-big $border-radius-big 0 0;
$border-radius-bottom: 0 0 $border-radius-big $border-radius-big;
$amount-color: $color-secondary;
$footer-background: #003216; // primary 700
$footer-border: none;
$footer-text-color: white;
$header-background-color: white;
$header-border-bottom: none;
$header-menu-active-color: $color-secondary;
$header-text-color: $black;
$header-text-transform: initial;
$account-aside-header-item-color: $black;

$auth-header-background-color: $color-primary;
$auth-header-mobile-background-color: white;
$auth-header-text-color: white;
$auth-header-mobile-text-color: $black;

$cookie-policy-footer-background: $black;
$cookie-policy-footer-color: white;
$cookie-policy-footer-text-transform: uppercase;
$phone-number-color: $color-secondary;

$hamburger-layer-color: $black;
$common-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

$badge-background-color: $color-secondary;

$payment-banner-background-color: #bc996e;
$background-primary-gradient: linear-gradient(90deg, #8d6a3f 0%, #bc996e 100%);

/***************************
 * = FORM
 ***************************/

$active-color: $color-primary;
$active-color-dark: $color-primary-dark;
$active-color-light: $color-primary-light;
$input-border-color: $grey-medium;
$input-border: 1px solid $active-color;
$input-border-radius: $border-radius-small;
$radio-checked-background: $grey-light;
$radio-hover-border-color: $active-color-light;
$radio-outline: none;
$radio-outline-error: none;
$input-shadow-box: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
$button-primary-background-color: $color-primary;
$button-primary-background-color-dark: $color-primary-dark;
$button-box-shadow: none;
$button-border-radius: 5px;

%master-button {
	border-radius: $button-border-radius;
}

%brand-button--secondary {
	box-shadow: 0 1px 2px 0 #33333340;
}

/***************************
 * = FICHE PRODUIT
 ***************************/
$product-accommodation-text-transform: uppercase;

$product-deal-background-color: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 80.73%);
$product-deal-text-color: white;

$offer-mobile-background-color: $color-primary;

$bloc-contact-title-case: uppercase;
$bloc-contact-body-background: $color-primary;
$bloc-contact-text-color: $black;

$product-aside-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);

$datepicker-background-color: $color-primary;

/***************************
 * = MOTEUR DE RECHERCHE
 ***************************/

$search-engine-offer-color: $black;

/***************************
 * = SMARTDP
 ***************************/

$smartdp-amount-color: $black;
$smartdp-flash-sale-color: $color-primary;
$smartdp-search-marketing-color: $color-primary;
$menu-account-menu-item-color: $color-primary;
$menu-account-submenu-item-color: black;

/***************************
 * = TUNNEL DE REZA | COMMUN
 ***************************/
$booking-bloc-title-main-background-color: $color-primary;
$booking-amount-upgrade-price-color: $black;
$booking-item-margin: 15px;
$booking-cta-background-color: #0abf74;

$quotation-price-detail-toggle-button-background: $black;
$quotation-price-detail-total-background: $black;
$quotation-price-detail-total-border: 1px solid $black;
$quotation-price-detail-total-font-color: white;
$quotation-price-detail-pay-now-border: 1px solid white;
$quotation-price-detail-text-color: white;
$quotation-price-detail-total-margin-bottom: 10px;

/***************************
 * = TUNNEL DE REZA | QUOTE
 ***************************/
$booking-badge-background-color: $color-secondary;
$badge-font-color: white;
$badge-border: none;
$badge-border-radius: $border-radius-big;

$quotation-filter-sort-background: white;

$flight-filter-button-background-color: $grey-light;
$flight-filter-button-color: $black;
$flight-filter-button-border-radius: $border-radius-big;
$flight-filter-background-color: $color-secondary;

$quotation-luggage-included-border-radius: $border-radius-small;
$quotation-luggage-included-outline: unset;

$quotation-cabins-margin: 0;
$quotation-cabins-filter-header-background: $grey-light;
$quotation-cabins-filter-header-border: none;
$quotation-cabins-filter-border-bottom: 2px solid $flight-filter-background-color;

/***************************
 * = TUNNEL DE REZA | PAYMENT
 ***************************/
$insurances-radio-second-color: #6c6c6c;

/***************************
 * = TUNNEL DE REZA | CONFIRMATION
 ***************************/
$booking-confirmation-reference-color: $color-secondary;

/***************************
 * = SMARTDP
 ***************************/
$smartdp-search-form-background-color: $color-primary;

/***************************
 * = LISTING
 ***************************/
$product-cta-background-color: $button-primary-background-color;
$product-cta-border: 1px solid $button-primary-background-color;
$product-cta-button-radius: none;
$product-footer-border: none;
$product-deal-public-price-line-through-color: $black;
$product-name-text-transform: none;
$product-intemporelle-color: $color-primary;
$listing-actionbar-title-display-on-mobile: block;
$listings-product-background-color: $grey-light;
$listing-header-background-color: $grey-light;
$listing-header-height: 273px;
$colored-product-badge-background-color: $color-primary;
$colored-product-badge-text-transform: uppercase;
$colored-product-badge-border-radius: 5px;
$merchandising-simple-width: 100%;
$listing-time-border-radius: 50px;
$listing-time-background-color: $color-primary;

/***************************
 * = MERCHANDISING
 ***************************/
$merchandising-listing-background-color: $grey-light;

/***************************
 * = AUTH
 ***************************/
// auth form background that appears on a background that do not change (auth, booking/auth, home)
$auth-form-background-static: white;
$auth-form-text-color-static: $black;
// auth form background that appears on a background that can change over time (landing-page, landing product)
$auth-form-background-dynamic: rgba(0, 0, 0, 0.7);
$auth-form-text-color-dynamic: white;
$auth-marketing-message-shadow: none;

/***************************
 * = MENU
 ***************************/

$menu-background-color: $color-primary;
$menu-text-color: white;

/***************************
 * = PLACEHOLDERS
 ***************************/

%booking-title {
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	box-sizing: border-box;
	text-align: center;
	min-height: 40px;
	padding: 10px 0;
}

%input-label-touched {
	position: absolute;
	top: 0;
	left: 5px;
	font-size: 1rem;
	color: $black;
	background: none;
	padding-right: 10px;
	padding-left: 10px;
}

$mobile-auth-background-color: $color-primary;
$listing-header-filter-max-width: none;
$listing-header-sticky-container-box-shadow: none;
$product-filter-header-box-shadow: none;
$products-filter-selected-border: none;
$products-master-filter-box-shadow: unset;
$aside-product-filters-menu-filters-title-height: 40px;

$marketing-slide-small-logo: url("/static/icons/icon-marketing-slide-small-logo-fr.svg");
$marketing-slide-small-1: url("/static/icons/icon-marketing-ek-1.svg");
