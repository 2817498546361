@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$listing-time-border-radius: 10px !default;
$listing-time-background-color: $color-primary !default;

.product-top-bar {
	padding: 8px 0 0 8px;

	.product-time {
		text-transform: none;
	}

	.badge {
		border-radius: $listing-time-border-radius;
		background: $listing-time-background-color;
		padding-right: 15px;
		padding-left: 15px;
	}

	@include gridle_state(md) {
		.badge {
			padding-right: 20px;
			padding-left: 20px;
		}
	}
}
