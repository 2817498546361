@import "../../common-typography";

:root {
	//	Override the default font sizes here
	--font-family: saudia-regular, sans-serif;
	--font-family-bold: saudia-bold, sans-serif;
}

%font-normal {
	font-family: Helvetica, sans-serif;
}

%font-bold {
	font-family: Helvetica, sans-serif;
	font-weight: bold;
}
